import React from "react"
import { graphql } from "gatsby"

//import Layout from "../components/layout"
import SEO from "../components/seo"

class NotFoundPage extends React.Component {
  render() {

    return (
    <main id="site-main" className="site-main">
        <SEO title="404: Not Found" />
        <div style={{ height: '100vh',display: 'flex', justifyContent : 'center', alignItems: 'center' }}>
            <div style={{ textAlign: 'center' }}>
                <h1>Not Found</h1>
                <p style={{ color: 'black', lineHeight : 0, padding: 0 }}>You just hit a route that doesn&#39;t exist... Sorry.</p>
            </div>
        </div>
    </main>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
